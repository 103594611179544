<template>
    <div class="live-content">
        <el-scrollbar>
            <el-breadcrumb class="live-breadcrumb" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>直播选品</el-breadcrumb-item>
                <el-breadcrumb-item>选择商品</el-breadcrumb-item>
                <el-breadcrumb-item>商品数据</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="top-title">商品数据</div>
            <div class="data-box">
                <div class="data-title">月销量趋势</div>
                <div class="data-content" id="monthSale"></div>
            </div>
            <div class="data-box">
                <div class="data-title">月浏览量趋势</div>
                <div class="data-content" id="monthView"></div>
            </div>
            <div class="data-box">
                <div class="data-title">月曝光量趋势</div>
                <div class="data-content" id="monthExposure"></div>
            </div>
            <div class="data-box">
                <div class="data-title">成交指数</div>
                <div class="data-content" id="dealCount"></div>
            </div>
            <div class="data-box">
                <div class="data-title">人数</div>
                <div class="data-content" id="peopleCount"></div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import {live_script_goods_data} from '@/utils/apis'

    export default {
        name: "liveSelectionDetail",
        data() {
            return {
                goodsData: [],
                monthSale: null,
                monthSaleData: [],
                monthView: null,
                monthViewData: [],
                monthExposure: null,
                monthExposureData: [],
                dealCount: null,
                singleCountData: [],
                turnoverCountData: [],
                peopleCount: null,
                searchCountData: [],
                visitorCountData: [],
                dateList: [],
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.monthSale = this.$echarts.init(document.getElementById('monthSale'));
                this.monthView = this.$echarts.init(document.getElementById('monthView'));
                this.monthExposure = this.$echarts.init(document.getElementById('monthExposure'));
                this.dealCount = this.$echarts.init(document.getElementById('dealCount'));
                this.peopleCount = this.$echarts.init(document.getElementById('peopleCount'));

                let resizeTimer = null;
                window.onresize = () => {
                    clearTimeout(resizeTimer);
                    resizeTimer = setTimeout(() => {
                        this.monthSale.resize()
                    }, 1000)
                }
                this.getGoodsDetail()
            })
        },
        methods: {
            getGoodsDetail() {
                let good_id = this.$route.query.id
                this.monthSaleData = []
                live_script_goods_data(good_id).then(res => {
                    this.goodsData = res.data.goods_data
                    this.dateList = res.data.date
                    this.goodsData.forEach(item => {
                        this.monthSaleData.push(item.goods_sales_count)
                        this.monthViewData.push(item.goods_view_count)
                        this.monthExposureData.push(item.goods_exposure_count)
                        this.singleCountData.push(item.goods_single_count)
                        this.turnoverCountData.push(item.goods_turnover_count)
                        this.searchCountData.push(item.goods_search_count)
                        this.visitorCountData.push(item.goods_visitor_count)
                    })
                    this.setMonthSaleData()
                    this.setMonthViewData()
                    this.setMonthExposure()
                    this.setDealCount()
                    this.setPeopleCount()
                })
            },
            setMonthSaleData() {
                let that = this
                this.monthSale.setOption(
                    {
                        xAxis: {
                            type: 'category',
                            data: that.dateList,
                            axisLabel: {
                                interval: 2,
                                textStyle: {
                                    color: '#fff'
                                },
                            },
                            axisTick: {
                                show: false
                            },
                        },
                        yAxis: {
                            type: 'value',
                            name: "销量",
                            position: 'left',
                            axisLabel: {
                                textStyle: {
                                    color: '#fff'
                                },
                            },
                            nameTextStyle: {
                                padding: [0, 40, 10, 0],
                                fontSize: 14
                            },
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: '#fff',
                                    opacity: '0.8'
                                }
                            },
                            splitLine: {
                                lineStyle: {
                                    type: 'solid',
                                    color: '#303369',
                                    width: '1'
                                }
                            },
                        },
                        tooltip: {
                            trigger: 'axis',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            textStyle: {
                                color: '#fff',
                            },
                            axisPointer: {
                                type: 'line',
                                lineStyle: {
                                    color: '#564CED',
                                    type: 'solid',
                                    width: 30,
                                    opacity: 0.2,
                                },
                            }
                        },
                        grid: {
                            top: 40,
                            left: '4%',
                            right: 0,
                            bottom: 25,
                        },
                        series: [{
                            name: '销量',
                            type: 'bar',
                            data: that.monthSaleData,
                            barWidth: 12,
                            color: '#4B45FF',
                            itemStyle: {
                                //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                                normal: {
                                    //柱形图圆角，初始化效果
                                    barBorderRadius: [6, 6, 0, 0]
                                }
                            },
                        }]
                    }
                )
            },
            setMonthViewData() {
                let that = this
                this.monthView.setOption(
                    {
                        xAxis: {
                            type: 'category',
                            data: that.dateList,
                            axisLabel: {
                                interval: 2,
                                textStyle: {
                                    color: '#fff'
                                },
                            },
                            axisTick: {
                                show: false
                            },
                        },
                        yAxis: {
                            type: 'value',
                            name: "浏览量",
                            position: 'left',
                            axisLabel: {
                                textStyle: {
                                    color: '#fff'
                                },
                            },
                            nameTextStyle: {
                                padding: [0, 40, 10, 0],
                                fontSize: 14
                            },
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: '#fff',
                                    opacity: '0.8'
                                }
                            },
                            splitLine: {
                                lineStyle: {
                                    type: 'solid',
                                    color: '#303369',
                                    width: '1'
                                }
                            },
                        },
                        tooltip: {
                            trigger: 'axis',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            textStyle: {
                                color: '#fff',
                            },
                            axisPointer: {
                                type: 'line',
                                lineStyle: {
                                    color: '#564CED',
                                    type: 'solid',
                                    width: 30,
                                    opacity: 0.2,
                                },
                            }
                        },
                        grid: {
                            top: 40,
                            left: '4%',
                            right: 0,
                            bottom: 25,
                        },
                        series: [{
                            name: '浏览量',
                            type: 'bar',
                            data: that.monthViewData,
                            barWidth: 12,
                            color: '#4B45FF',
                            itemStyle: {
                                //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                                normal: {
                                    //柱形图圆角，初始化效果
                                    barBorderRadius: [6, 6, 0, 0]
                                }
                            },
                        }]
                    }
                )
            },
            setMonthExposure() {
                let that = this
                this.monthExposure.setOption(
                    {
                        xAxis: {
                            type: 'category',
                            data: that.dateList,
                            axisLabel: {
                                interval: 2,
                                textStyle: {
                                    color: '#fff'
                                },
                            },
                            axisTick: {
                                show: false
                            },
                        },
                        yAxis: {
                            type: 'value',
                            name: "曝光量",
                            position: 'left',
                            axisLabel: {
                                textStyle: {
                                    color: '#fff'
                                },
                            },
                            nameTextStyle: {
                                padding: [0, 40, 10, 0],
                                fontSize: 14
                            },
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: '#fff',
                                    opacity: '0.8'
                                }
                            },
                            splitLine: {
                                lineStyle: {
                                    type: 'solid',
                                    color: '#303369',
                                    width: '1'
                                }
                            },
                        },
                        tooltip: {
                            trigger: 'axis',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            textStyle: {
                                color: '#fff',
                            },
                            axisPointer: {
                                type: 'line',
                                lineStyle: {
                                    color: '#564CED',
                                    type: 'solid',
                                    width: 30,
                                    opacity: 0.2,
                                },
                            }
                        },
                        grid: {
                            top: 40,
                            left: '4%',
                            right: 0,
                            bottom: 25,
                        },
                        series: [{
                            name: '曝光量',
                            type: 'bar',
                            data: that.monthExposureData,
                            barWidth: 12,
                            color: '#4B45FF',
                            itemStyle: {
                                //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                                normal: {
                                    //柱形图圆角，初始化效果
                                    barBorderRadius: [6, 6, 0, 0]
                                }
                            },
                        }]
                    }
                )
            },
            setDealCount() {
                let that = this
                this.dealCount.setOption(
                    {
                        legend: {
                            textStyle: {
                                color: '#fff',
                                fontSize: 14,
                            },
                            bottom: 0,
                            itemGap: 20,
                        },
                        xAxis: {
                            type: 'category',
                            data: that.dateList,
                            axisLabel: {
                                interval: 2,
                                textStyle: {
                                    color: '#fff'
                                },
                            },
                            axisTick: {
                                show: false
                            },
                        },
                        yAxis: [
                            {
                                type: 'value',
                                name: "成交金额",
                                position: 'left',
                                axisLabel: {
                                    textStyle: {
                                        color: '#fff',
                                    },
                                },
                                nameTextStyle: {
                                    padding: [0, 40, 10, 0],
                                    fontSize: 14
                                },
                                axisLine: {
                                    show: false,
                                    lineStyle: {
                                        color: '#fff',
                                        opacity: '0.8',
                                    }
                                },
                                splitLine: {
                                    lineStyle: {
                                        type: 'solid',
                                        color: '#303369',
                                        width: '1'
                                    }
                                },
                            },
                            {
                                type: 'value',
                                name: "成交单量",
                                position: 'right',
                                axisLabel: {
                                    textStyle: {
                                        color: '#fff',
                                    },
                                },
                                nameTextStyle: {
                                    padding: [0, 0, 10, 40],
                                    fontSize: 14
                                },
                                axisLine: {
                                    show: false,
                                    lineStyle: {
                                        color: '#fff',
                                        opacity: '0.8'
                                    }
                                },
                                splitLine: {
                                    lineStyle: {
                                        type: 'solid',
                                        color: '#303369',
                                        width: '1'
                                    }
                                },
                            }
                        ],
                        tooltip: {
                            trigger: 'axis',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            textStyle: {
                                color: '#fff',
                            },
                            axisPointer: {
                                type: 'line',
                                lineStyle: {
                                    type: 'solid',
                                },
                            }
                        },
                        grid: {
                            top: 40,
                            left: '4%',
                            right: '4%',
                            bottom: 60,
                        },
                        series: [{
                            name: '成交金额',
                            type: 'line',
                            data: that.turnoverCountData,
                            barWidth: 12,
                            symbolSize: 12,
                            smooth: true,
                            symbol: 'circle',
                            showSymbol: false,
                            itemStyle: {
                                color: '#12C4C4',
                                emphasis: {
                                    borderColor: '#12C4C4',
                                    borderWidth: 4,
                                    shadowColor: '#000',
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowOffsetY: 0
                                }
                            },
                        }, {
                            name: '成交单量',
                            type: 'line',
                            yAxisIndex: 1,
                            data: that.singleCountData,
                            barWidth: 12,
                            symbolSize: 12,
                            smooth: true,
                            symbol: 'circle',
                            showSymbol: false,
                            itemStyle: {
                                color: '#4B45FF',
                                emphasis: { // 鼠标经过时：
                                    color: '#4B45FF',
                                    borderColor: '#4B45FF',
                                    borderWidth: 4,
                                    shadowColor: '#000',
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowOffsetY: 0
                                },
                            },
                        }]
                    }
                )
            },
            setPeopleCount() {
                let that = this
                this.peopleCount.setOption(
                    {
                        legend: {
                            textStyle: {
                                color: '#fff',
                                fontSize: 14,
                            },
                            bottom: 0,
                            itemGap: 20,
                        },
                        xAxis: {
                            type: 'category',
                            data: that.dateList,
                            axisLabel: {
                                interval: 2,
                                textStyle: {
                                    color: '#fff'
                                },
                            },
                            axisTick: {
                                show: false
                            },
                        },
                        yAxis: [
                            {
                                type: 'value',
                                name: "搜索人数",
                                position: 'left',
                                axisLabel: {
                                    textStyle: {
                                        color: '#fff',
                                    },
                                },
                                nameTextStyle: {
                                    padding: [0, 40, 10, 0],
                                    fontSize: 14
                                },
                                axisLine: {
                                    show: false,
                                    lineStyle: {
                                        color: '#fff',
                                        opacity: '0.8',
                                    }
                                },
                                splitLine: {
                                    lineStyle: {
                                        type: 'solid',
                                        color: '#303369',
                                        width: '1'
                                    }
                                },
                            },
                            {
                                type: 'value',
                                name: "访客人数",
                                position: 'right',
                                axisLabel: {
                                    textStyle: {
                                        color: '#fff',
                                    },
                                },
                                nameTextStyle: {
                                    padding: [0, 0, 10, 40],
                                    fontSize: 14
                                },
                                axisLine: {
                                    show: false,
                                    lineStyle: {
                                        color: '#fff',
                                        opacity: '0.8'
                                    }
                                },
                                splitLine: {
                                    lineStyle: {
                                        type: 'solid',
                                        color: '#303369',
                                        width: '1'
                                    }
                                },
                            }
                        ],
                        tooltip: {
                            trigger: 'axis',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            textStyle: {
                                color: '#fff',
                            },
                            axisPointer: {
                                type: 'line',
                                lineStyle: {
                                    type: 'solid',
                                },
                            }
                        },
                        grid: {
                            top: 40,
                            left: '4%',
                            right: '4%',
                            bottom: 60,
                        },
                        series: [{
                            name: '搜索人数',
                            type: 'line',
                            data: that.searchCountData,
                            barWidth: 12,
                            symbolSize: 12,
                            smooth: true,
                            symbol: 'circle',
                            showSymbol: false,
                            itemStyle: {
                                color: '#12C4C4',
                                emphasis: {
                                    borderColor: '#12C4C4',
                                    borderWidth: 4,
                                    shadowColor: '#000',
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowOffsetY: 0
                                }
                            },
                        }, {
                            name: '访客人数',
                            type: 'line',
                            yAxisIndex: 1,
                            data: that.visitorCountData,
                            barWidth: 12,
                            symbolSize: 12,
                            smooth: true,
                            symbol: 'circle',
                            showSymbol: false,
                            itemStyle: {
                                color: '#4B45FF',
                                emphasis: { // 鼠标经过时：
                                    color: '#4B45FF',
                                    borderColor: '#4B45FF',
                                    borderWidth: 4,
                                    shadowColor: '#000',
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowOffsetY: 0
                                },
                            },
                        }]
                    }
                )
            },
        }
    }
</script>

<style scoped lang="scss">
    .live-content {
        height: 100%;
        display: flex;
        flex-direction: column;

        .el-scrollbar {
            margin: 20px;
            height: calc(100% - 40px);
        }

        ::v-deep .el-scrollbar__wrap {
            height: 100%;
            overflow-x: hidden;

            .el-scrollbar__view {
                height: 100%;
            }
        }

        .live-breadcrumb {
            ::v-deep.el-breadcrumb__item {

                &:last-child .el-breadcrumb__inner {
                    color: #ABABBB;
                }

                .el-breadcrumb__inner {
                    color: #5C5C6F;
                }

                .el-breadcrumb__separator {
                    color: #5C5C6F;
                }
            }
        }

        .top-title {
            margin-top: 18px;
            font-size: 18px;
            font-weight: 500;
            color: #FFFFFF;
        }

        .data-box {
            margin-top: 20px;
            background: #0C0E3F;
            box-shadow: 0px 0px 10px 0px rgba(11, 12, 28, 0.04);
            border-radius: 20px;
            padding: 20px;

            .data-title {
                font-size: 16px;
                font-weight: 500;
                color: #FFFFFF;
            }

            .data-content {
                height: 350px;
                width: 100%;
                margin-top: 30px;
            }
        }
    }
</style>